import React from "react";
import { makeStyles } from "@material-ui/core";

interface IProps {
  src: string;
  alt?: string;
  absolute?: boolean;
  objectFit?: "cover" | "contain";
}

const useStyle = makeStyles({
  imageBox: (props: IProps) => ({
    width: "100%",
    height: "100%",
    position: props.absolute ? "absolute" : "static",
    objectFit: props.objectFit ? props.objectFit : "cover",
    display: "block"
  }),
});

export const ImageBox = (props: IProps) => {
  const styles = useStyle(props);
  return (
    <><img className={styles.imageBox} src={props.src} alt={props.alt ? props.alt : "decoration"} /></>
  );
};
