import React from "react";
import { ScrollMarker } from "./ScrollHelper";
import { Hero } from "./Hero";
import { PortfolioComponent } from "./PortfolioComponent";
import { Row } from "./Row";
import { ContactComponent } from "./ContactComponent";
import { ImageBox } from "./ImageBox";

import coffee from "./assets/coffee512.jpg";
import solidayApp from "./assets/solidayApp.jpg";
import villa1 from "./assets/villa1.jpg";
import archviz from "./assets/archviz.jpg";
import archviz2 from "./assets/archviz2.jpg";
import archviz3 from "./assets/archviz3.jpg";
import villa1Video from "./assets/villa1.mp4";
import corvette3 from "./assets/corvette3.jpg";
import corvette6 from "./assets/corvette6.jpg";
import archvizVideo from "./assets/archviz.mp4";
import motionGfx from "./assets/motionGfx.jpg";
import motionVideo from "./assets/motion.mp4";
import ref_sdr from "./assets/sdr.jpg";
import ref_case from "./assets/case.jpg";
import ref_lotto from "./assets/lotto.jpg";
import ref_newHolland from "./assets/newHolland.jpg";
import ref_fuzo from "./assets/fuzo.jpg";
import loxoneVisu from "./assets/LoxoneVisu.jpg";
import loxoneVideo from "./assets/Loxone_02.mp4";
import { useWebsiteContext } from "./WebsiteContext";

export const MainPage = (props: {
}) => {
  const context = useWebsiteContext();
  return (
    <>
      <ScrollMarker id="home" />
      <Hero onClick={() => context.switchToSection && context.switchToSection("#portfolio")} />
      <ScrollMarker id="portfolio" />
      <PortfolioComponent
        header={"Die Soliday App"}
        subHeader={(< a href="https://www.soliday.eu" > www.soliday.eu</a>)}
        description={"Mit Hilfe der Soliday App können Fachhändler Projekte direkt in der App in 3d planen, bestellen und an Kunden schicken."}
        items={[
          {
            image: solidayApp,
            imageLink: "https://www.youtube.com/watch?v=khOncWPUcYI",
          }
        ]}
        skin={{ backgroundColor: "#white", subHeaderTextColor: "#4c586a" }}
      />
      <PortfolioComponent
        header={"Visualisierungen"}
        subHeader={"Realtime & Prerendered"}
        description={"... aller Art - auch im Browser"}
        aspect={0.5}
        items={[
          {
            header: "Architektur Visualisierung",
            description: "Echtzeit Rendering...",
            image: archviz,
            imageLink: archvizVideo,
            disclaimer: (<>Soundtrack Serenity von <a href="http://audionautix.com/">Audionautix</a> lizensiert unter <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution</a></>)
          },
          {
            header: "Architektur Visualisierung",
            description: "Echtzeit Rendering...",
            image: archviz2,
            imageLink: archvizVideo,
            disclaimer: (<>Soundtrack Serenity von <a href="http://audionautix.com/">Audionautix</a> lizensiert unter <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution</a></>)
          },
          {
            header: "Architektur Visualisierung",
            description: "Echtzeit Rendering...",
            image: archviz3,
            imageLink: archvizVideo,
            disclaimer: (<>Soundtrack Serenity von <a href="http://audionautix.com/">Audionautix</a> lizensiert unter <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution</a></>)
          },
          {
            image: corvette3,
            imageLink: archvizVideo,
          },
          {
            image: corvette6,
            imageLink: archvizVideo,
          }
        ]}
        swap={context.size !== "small"}
        skin={{ backgroundColor: "#ececec", subHeaderTextColor: "#4c586a" }}
      />
      <PortfolioComponent
        header={"Konfiguratoren"}
        subHeader={"Tag & Nacht"}
        description={"... gerne auch in 3d"}
        items={[
          {
            image: villa1,
            imageLink: villa1Video,
          }
        ]}
        skin={{ backgroundColor: "#dacfbd", subHeaderTextColor: "#4c586a" }}
      />
      <PortfolioComponent
        header={"Motion Graphics"}
        subHeader={""}
        description={""}
        items={[
          {
            image: motionGfx,
            imageLink: motionVideo,
          }
        ]}
        swap={context.size !== "small"}
        skin={{ backgroundColor: "#ffe8cb", subHeaderTextColor: "#4c586a" }}
      />
      <PortfolioComponent
        header={"Loxone - Visualisierungen"}        
        subHeader={"auf dem Tablet und im Browser"}
        description={"Individuelle Visualisierungen für Ihre Spezialanwendungen..."}
        items={[
          {
            image: loxoneVisu,
            imageLink: loxoneVideo,
          }
        ]}        
        skin={{ backgroundColor: "#ffe8cb", subHeaderTextColor: "#4c586a" }}
      />
      <PortfolioComponent
        header={"Weitere Referenzen"}
        subHeader={""}
        description={"Entwicklung diverser Apps, Spiele, ..."}
        items={[
          { image: ref_sdr, },
          { image: ref_case, },
          { image: ref_lotto, },
          { image: ref_newHolland, },
          { image: ref_fuzo, },
        ]}
        swap={context.size !== "small"}
        skin={{ backgroundColor: "#f3e2da", subHeaderTextColor: "#4c586a" }}
      />
      <ScrollMarker id="contacts" />
      <div> {/*important to work on safari*/}
        <Row>
          <ContactComponent />
          <ImageBox src={coffee} absolute={true} />
        </Row>
      </div>
    </>
  );
};
