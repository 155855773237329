import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import violin from "./assets/violin1024.jpg";
import { colors } from "./Definitions";

import fpCrop from "./assets/fp_crop.mp4";

const useStyle = makeStyles({
  hero: {
    textAlign: "center",
    backgroundSize: "cover",
    flex: "1 0 100%",
    backgroundImage: "url(" + violin + ")",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  innerBlock: {
    color: colors.white,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    width: "100%",
    "& h1": {
      fontSize: "400%",
      fontWeight: 500,
      margin: "5px auto",
      fontVariant: "small-caps"
    },
    "& p": {
      margin: "0 auto",
      marginTop: "25px",
      fontSize: "125%",
      width: "70%",
      fontVariant: "small-caps"
    },
    "& button": {
      marginTop: "30px",
      marginBottom: "30px",
      color: "white",
      borderColor: "white"
    },
  },
  innerBlock2: {
    padding: "10px"
  },
  fpVideo: {
    width: "100%",
    height: "100%",
    margin: "5px 0px"
  },
});

export const Hero = (props: {
  onClick?: () => void
}) => {
  const styles = useStyle();
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = React.useState(false);
  return (
    <div className={styles.hero} onClick={() => videoRef.current ? videoRef.current.play() : ""}>
      <div className={styles.innerBlock}>
        {!playing && <div className={styles.innerBlock2}>
          <h1>Fluchtpunkt IT</h1>
          <div className={styles.innerBlock2}>
            <p>We love the work we do.<br />Be part of it...</p>
          </div>
          <Button color={"primary"} variant={"outlined"} /* onClick={props.onClick}*/>START</Button>
        </div>}
        {<video onPlay={() => setPlaying(true)} onEnded={() => setPlaying(false)} style={{ display: playing ? "block" : "none" }} className={styles.fpVideo} ref={videoRef} src={fpCrop} controls={false} preload={"auto"}>
          Your browser does not support the video tag.
      </video>}
      </div>
    </div>
  );
};
