import React from "react";
import { makeStyles } from "@material-ui/core";
import ecg from "./assets/ecg.png";
import { colors } from "./Definitions";

const useStyle = makeStyles({
  imprint: {
    flex: 1,
  },
  header: {
    textAlign: "center",
    fontSize: "150%",
    fontWeight: 350,
    color: colors.darkgreyFont,
    margin: "30px"
  },
   content: {
    color: "#1d2024",
    textAlign: "justify",
    fontWeight: 300,
    margin: "30px",
    "& h4": {
      marginTop: "35px",
      marginBottom: "15px",
      fontSize: "110%",
      color: "#1d2024",
      fontWeight: 400,
    }
   }
});

export const ImprintPage = (props: {
}) => {
  const styles = useStyle();
  return (
    <div className={styles.imprint}>
      <div className={styles.header}>Impressum</div>
      <div className={styles.content}>
        <h4>Kontakt</h4>
        <p>
          Markus Wöß<br/>
          Markt 19<br/>
          4134 Putzleinsdorf<br/>
          Österreich<br/>
          +43 650 9219476<br/>
          office|at|fluchtpunkt.at
        </p>
        <h4>Unternehmen</h4>
        <p>UID: ATU65990877</p>
        <p>Behörde gem. ECG: Bezirkshauptmannschaft Rohrbach</p>
        <p>Unternehmensgegenstand: Individuelle Softwareentwicklung</p>

        <a target="_blank" rel="noopener noreferrer" href="https://www.wkoecg.at/Web/Ecg.aspx?FirmaID=0f5b07e2-17d4-4a37-84b2-380a07ab5427"><img src={ecg} alt={"WKO"} /></a>
      </div>
    </div>
  );
};
