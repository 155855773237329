import React from "react";
import { makeStyles } from "@material-ui/core";
import { Row } from "./Row";
import { colors } from "./Definitions";
import { PortfolioView } from "./PortfolioView";

interface IPortfolioSkin {
  backgroundColor: string;
  backgroundColorImage: string;
  headerTextColor: string;
  subHeaderTextColor: string;
  textColor: string;
}

const useStyle = makeStyles({
  portfolio: (skin?: Partial<IPortfolioSkin> | undefined) => ({
    width: "100%",
    color: "#afbac4",
    fontWeight: 300,
    backgroundColor: (skin && skin.backgroundColor) ? skin.backgroundColor : "#ffffff",
  }),
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    boxSizing: "border-box",
    padding: "5%"
  },
  imageMargin: {
    margin: "20px 0px 20px 0px",
  },
  image: {
    boxSizing: "border-box",
    position: "relative",
    height: "100%",
    // height: "300px"
  },
  header: {
    color: colors.appBar,
    fontSize: "170%",
    textAlign: "center"
  },
  subHeader:  (skin?: Partial<IPortfolioSkin>) => ({
    color: (skin && skin.subHeaderTextColor) ? skin.subHeaderTextColor : colors.navActive,
    fontSize: "90%",
  }),
  description: {
    marginTop: "15px",
    color: colors.appBar,
    fontSize: "100%",
  }
});

export interface IPortfolioItem {
  header?: string | JSX.Element;
  description?: string | JSX.Element;
  image: string;
  imageLink?: string;
  disclaimer?: string | JSX.Element;
}

export const PortfolioComponent = (props: {
  header: string | JSX.Element;
  subHeader: string | JSX.Element;
  description: string | JSX.Element;
  items: IPortfolioItem[];
  aspect?: number;
  swap?: boolean;
  skin?: Partial<IPortfolioSkin>;
}) => {
  const styles = useStyle(props.skin);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const item = props.items[currentIndex];
  const imageBox = <PortfolioView index={currentIndex} aspect={props.aspect} items={props.items} onChanged={(index) => setCurrentIndex(index)}/>;
  const first = (
    <div className={styles.text}>
      <div className={styles.header}>
        {props.header}
      </div>
      <div className={styles.subHeader}>
        {props.subHeader}
      </div>
      <div className={styles.description}>
        {props.description}
      </div>
    </div>);
  const second = (
    <div className={styles.image}>
      {/*item.imageLink && <a target="_blank" rel="noopener noreferrer" href={item.imageLink}>{imageBox}</a>*/}
      {item.imageLink && <div onClick={() => window.open(item.imageLink)}>{imageBox}</div>}
      {!item.imageLink && imageBox}
    </div>
  );
  return (
    <div className={styles.portfolio}>
      <Row>
        {props.swap ? second : first}
        {props.swap ? first : second}
      </Row>
    </div>
  );
};
