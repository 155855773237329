import React from "react";
import { IPortfolioItem } from "./PortfolioComponent";
import { ImageBox } from "./ImageBox";
import { PortfolioNavigation } from "./PortfolioNavigation";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column"
  },
  overlayTop: {
    flex: 1
  },
  overlayBottom: {
    flex: "0 0 auto"
  },
  disclaimer: {
    textAlign: "right",
    width: "100%"
  },
  disclaimerInner: {
    display: "inline-block",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    padding: "2px 4px",
    color: "rgb(255, 255, 255, 0.8)",
    fontSize: "70%",
    verticalAlign: "top"
  },
  info: {
    // color: "#fff",
    margin: "20px",
    // textShadow: " 1px 1px 1px rgba(0,0,0,1.0), 0px -1px 10px rgba(255,255,255, 1.0)"
    color: "#fff",
    // textShadow: "1px -1px 1px #000, 1px 1px 1px #000, -1px -1px 1px #000, -1px 1px 1px #000",
    textShadow: "-1px 1px 3px #000, 1px 1px 1px #000",
    // textShadow: "0.5px 0.5px 0px #eee, 1px 1px 0px #707070"
    // textShadow: "0 13.36px 8.896px #c4b59d,0 -2px 1px #fff"
    "& span": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      padding: "0px 4px"
    }
  },
  infoHeader: {
    fontSize: "110%"
  },
  infoDesription: {
    fontSize: "90%"
  }
});

function useInterval(callback: () => void, delay: number | undefined) {
  const savedCallback = React.useRef<() => void>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    if (delay !== undefined) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const PortfolioView = (props: {
  items: IPortfolioItem[],
  index: number,
  aspect?: number,
  onChanged: (index: number) => void
}) => {
  const styles = useStyles();
  const item = props.items[props.index];
  const lastChange = React.useRef(0);

  const intervalTime = 5000;

  useInterval(() => {
    if (Date.now() - lastChange.current! > intervalTime)
      props.onChanged((props.index + 1) % props.items.length);
  }, props.items.length > 1 ? intervalTime : undefined);

  return (
    <div style={props.aspect ? { width: "100%", paddingBottom: (props.aspect * 100) + "%", position: "relative"} : undefined}>
      <ImageBox src={item.image} absolute={props.aspect !== undefined} objectFit={"cover"} />
      <div className={styles.overlay}>
        <div className={styles.overlayTop}>
          {item.disclaimer && <div className={styles.disclaimer}><div className={styles.disclaimerInner}>{item.disclaimer}</div></div>}
        </div>
        <div className={styles.info}>
          {item.header && <div className={styles.infoHeader}><span>{item.header}</span></div>}
          {item.description && <div className={styles.infoDesription}><span>{item.description}</span></div>}
        </div>
        <div className={styles.overlayBottom}>
          {props.items.length > 1 && <PortfolioNavigation index={props.index} items={props.items} onClicked={(index) => {
            lastChange.current = Date.now();
            props.onChanged(index);
          }} />}
        </div>
      </div>
  </div>
  );
};
