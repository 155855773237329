export const debounce = (func: () => void, delay: number) => {
  let inDebounce: NodeJS.Timeout;
  return () => {
    clearTimeout(inDebounce);
    inDebounce = setTimeout(func, delay);
  };
};

export const throttle = (func: (...params: any[]) => void, limit: number) => {
  let lastFunc: NodeJS.Timeout;
  let lastRan = 0;
  return (...params: any[]) => {
    if (!lastRan) {
      func(...params);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if ((Date.now() - lastRan) >= limit) {
          func(...params);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};
