import React from "react";
import { makeStyles } from "@material-ui/core";
import { colors, constants } from "./Definitions";

const useStyle = makeStyles({
  privacy: {
    flex: 1,
  },
  header: {
    textAlign: "center",
    fontSize: "150%",
    fontWeight: 350,
    color: colors.darkgreyFont,
    margin: "30px"
  },
  content: {
    color: "#1d2024",
    textAlign: "justify",
    fontWeight: 300,
    margin: "30px",
    "& h4": {
      marginTop: "35px",
      marginBottom: "15px",
      fontSize: "110%",
      color: "#1d2024",
      fontWeight: 400,
    }
  }
});

export const PrivacyPage = (props: {
}) => {
  const styles = useStyle();
  return (
    <div className={styles.privacy}>
      <div className={styles.header}>Datenschutzbestimmungen</div>
      <div className={styles.content}>
        <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen und sind um größtmögliche Sorgfalt bemüht. In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte.</p>

        <h4>Verantwortlicher</h4>
        <p>Fluchtpunkt IT<br />Markus Wöß<br />office|at|fluchtpunkt.at<br />Nähere Informationen finden Sie im <a href="/pages/imprint">Impressum</a></p>

        <h4>Ihre Rechte</h4>
        <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, teilen Sie und dies bitte mit. Weiters können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde.</p>

        <h4>Rechtliche Grundlagen für die Erhebung und Nutzung von personenbezogenen Daten</h4>
        <p>Wir erheben, nutzen und verwalten personenbezogene Daten nur dann, wenn ein berechtigtes Interesse im Rahmen des Betriebs der Webseite bzw. der laufenden Geschäftstätigkeiten besteht. Insbesondere:</p>
        <ul>
          <li>für die Beantwortung von Anfragen</li>
          <li>für technischen Support</li>
          <li>für die Bearbeitung von Angeboten und Aufträgen</li>
          <li>für den Betrieb und die Verwaltung unserer Webseite</li>
          <li>für die Vermeidung und Aufdeckung von Betrugsfällen und Straftaten</li>
          <li>wenn wir aus rechtlichen Gründen dazu verpflichtet sind</li>
        </ul>

        <h4>Nutzung der personenbezogenen Daten</h4>
        <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten Datei,
          Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider (Zugriffsdaten) enthält und den Abruf dokumentiert.
          Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite sowie der Verbesserung unseres Angebots ausgewertet.</p>
        <p>Anrede, Name, Vorname, E-Mail Adresse, Firma, Straße, Hausnummer, Postleitzahl, Ort/Stadt, Land, Telefonnummer, sowie Ihre persönlichen Wünsche, Auswahlkriterien und Anforderungen in Bezug auf konkrete Anfragen oder Dienstleistungen. Wir nutzen diese personenbezogenen Daten, um Ihre Anfragen zu bearbeiten und/oder um die angefragten Dienstleistungen zu erbringen beziehungsweise die angefragten Informationen zu liefern.</p>
        <p>Im Falle eines Auftrages bzw. zur Angebotslegung werden auch Bankdaten, UID Nummer, Firmendaten gespeichert um Angebote und Rechnungen legen zu können. Soweit es im Rahmen der Geschäftstätigkeiten notwendig ist, werden diese Daten auch an Dritte (wie z.b. Steuerberater, Rechtsanwälte, Finanzamt, Auftragsverarbeiter ..) weitergegeben.</p>

        <h4>Cookies</h4>
        <p>Unsere Website speichert momentan keine Cookies. Wir behalten uns aber vor, dies in Zukunft für technisch notwendige Cookies zu ändern. Bei Cookies handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Wir verlinken auf andere Seite die möglicherweise Cookies speichern (wie z.b. Youtube Video).</p>
        <p>Cookies können über die Browser Einstellungen gelöscht werden.</p>

        <h4>Speicherung lokaler Daten ("local storage")</h4>
        <p>Wir nutzen den lokalen Web Speicher um den Webauftritt für Sie zu optimieren und Ihre Einstellungen zu sichern. Über die Browser Einstellungen können Sie lokal gespeicherte Daten jederzeit löschen</p>
        <p>Bei der Deaktivierung von lokalem Speicher kann die Funktionalität unserer Website eingeschränkt sein.</p>
        <p>Momentan werden lediglich Informationen bezüglich der Zustimmung zu den Datenschutzbestimmungen gespeichert.</p>

        <h4>Daten aus anderen Quellen</h4>
        <p>Soweit nach geltendem Recht zulässig, können wir die von Ihnen bereitgestellten Daten mit anderen Daten zusammenführen, die wir bereits zu einem konformen Zweck über Sie erfasst und gespeichert haben.</p>

        <h4>Weitergabe von Daten an Dritte</h4>
        <p>Bestimmte Dienstleistungen erbringen wir unter Mitwirkung von Dritten. Wir haben diese Dienstleister sorgfältig ausgewählt und entsprechende Maßnahmen zum Schutz Ihrer personenbezogenen Daten getroffen.</p>

        <h5>Web Analyse Tools</h5>
        <p>Die Webseite verzichtet zur Zeit auf Tracking Tools wie z.b. Google Analytics.</p>

        <h5>Social Media Plugins</h5>
        <p>Die Webseite verzichtet zur Zeit auf Social Media Plugins.</p>

        <h4>Newsletter</h4>
        <p>Zur Zeit verzichten wir auf Newsletter</p>

        <h4>Datensicherheit durch technische und organisatorische Maßnahmen</h4>
        <p>Fluchtpunkt IT wird die Sicherheit gemäß DSGVO herstellen. Bei den zu treffenden Maßnahmen handelt es sich um Maßnahmen der Datensicherheit und zur Gewährleistung eines dem Risiko angemessenen Schutzniveaus hinsichtlich der Vertraulichkeit, der Integrität, der Verfügbarkeit sowie der Belastbarkeit der Systeme.
        Die technischen und organisatorischen Maßnahmen unterliegen dem technischen Fortschritt und der Weiterentwicklung und werden fortlaufend optimiert.</p>

        <h4>Änderung der Datenschutzerklärung</h4>
        <p>Wir behalten und das Rect, die Datenschutzerklärung jederzeit anpassen zu dürfen. Die aktualisierte Datenschutzerklärung wird jeweils auf unserer Website veröffentlicht und erfordert eine neuerliche Einwilligungen von Ihnen.</p>

        <p>Letztes Update: {new Date(constants.currentPrivacyVersion).toLocaleDateString()}</p>
      </div>
    </div>
  );
};
