import React, { useContext } from "react";

export interface IWebsiteContext {
  size: "small" | "large";
  termsAccepted: number;
  switchToSection?: (section: string) => boolean;
}

export const WebsiteContext = React.createContext<IWebsiteContext>({ size: "small", termsAccepted: 0 });

export const useWebsiteContext = () => useContext(WebsiteContext);
