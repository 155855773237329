import React from "react";
import {makeStyles} from "@material-ui/core";
import { colors } from "./Definitions";

const useStyle = makeStyles({
  pageHeader: {
    width: "100%",
    paddingTop: "40px",
    paddingBottom: "20px",
    textAlign: "center"
  },
  header: {
    fontSize: "1.8em",
    fontWeight: 300,
    textTransform: "capitalize",
    color: "white",
    "& span": {
      color: colors.navActive,
      fontWeight: 500
    }
  },
  borderStyle: {
    borderBottom: "2px solid rgba(236,239,241,.1)",
    margin: "10px auto",
    width: "40%",
    position: "relative",
    "& div": {
      content: " ",
      height: "7px",
      width: "60px",
      left: "50%",
      top: "-3px",
      marginLeft: "-30px",
      position: "absolute",
      backgroundColor: colors.navActive
    }
  }
});

export const PageHeader = (props: {
  text: string
}) => {
  const styles = useStyle();
  const firstLength = props.text.lastIndexOf(" ");
  const first = firstLength !== -1 ? props.text.substring(0, firstLength) : props.text;
  const second = firstLength !== -1 ? props.text.substring(firstLength + 1) : "";
  return (
   <div className={styles.pageHeader}>
      <div className={styles.header}>
        {first} <span>{second}</span>
      </div>
      <div className={styles.borderStyle}>
        <div></div>
      </div>
   </div>
  );
};
