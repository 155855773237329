import React from "react";
import { makeStyles } from "@material-ui/core";
import { PageHeader } from "./PageHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeEurope, faEnvelope, faPhone, IconDefinition } from "@fortawesome/pro-duotone-svg-icons";
import { colors } from "./Definitions";

const useStyle = makeStyles({
  contacts: {
    width: "100%",
    color: "#afbac4",
    fontWeight: 300,
    backgroundColor: colors.menuColor,
    boxSizing: "border-box",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "5%"
  },
  iconElement: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  icon: {
    color: "white"
  },
  right: {
    color: "#afbac4",
  }
});

export const IconElement = (props: {
  icon: IconDefinition,
  text?: string,
  children?: JSX.Element | JSX.Element[]
}) => {
  const styles = useStyle();
  return (
    <div className={styles.iconElement}>
      <FontAwesomeIcon className={styles.icon} icon={props.icon} size={"lg"} />
      <div className={styles.right}>
        {props.text && props.text}
        {props.children && props.children}
      </div>
    </div>
  );
};

export const ContactComponent = (props: {
}) => {
  const styles = useStyle();
  return (
    <div className={styles.contacts}>
      <PageHeader text={"Treten Sie mit uns in Kontakt"} />
      <p>Gemeinsam können wir Ihre Ideen umsetzen. Starten wir los!</p>
      <IconElement icon={faGlobeEurope}><div>Markt 19<br/>4134 Putzleinsdorf<br/>Austria</div></IconElement>
      <IconElement icon={faPhone} text={"+43 650 9219476"}/>
      <IconElement icon={faEnvelope} text={"office@fluchtpunkt.at"}/>
    </div>
  );
};
