import React from "react";
import { makeStyles } from "@material-ui/core";
import { colors, Pages, IPageItem } from "./Definitions";

const useStyle = makeStyles({
  footer: {
    width: "100%",
    backgroundColor: colors.appBar,
  },
  inner: {
    display: "flex",
    flexDirection: "row",
    color: colors.navInactive,
    fontWeight: 300,
    "& ul": {
      listStyle: "none",
      display: "flex",
      flexDirection: "row",
      margin: 0,
      padding: 0,
      paddingRight: "20px"
    },
    "& li": {
      margin: "10px"
    }
  },
  spacer: {
    flex: 1
  },
  list: {
    flex: "0 0 auto",
    color: colors.navInactive
  },
  navSelected: {
    color: colors.navActive
  },
});

export const Footer = (props: {
  onPageClicked: (page: Pages) => void,
  selectedPage: string,
  pages: IPageItem[]
}) => {
  const styles = useStyle();
  return (
    <div className={styles.footer}>
      <div className={styles.inner}>
        <div className={styles.spacer} />
        <div className={styles.list}>
          <ul>
            {props.pages.map((page) => {
              return <li key={page.page} className={props.selectedPage === page.page ? styles.navSelected : ""} onClick={() => props.onPageClicked(page.page)}>{page.text}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
