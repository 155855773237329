import React from "react";
import { makeStyles } from "@material-ui/core";
import { IPortfolioItem } from "./PortfolioComponent";

const useStyle = makeStyles({
  nav: {
    // backgroundColor: "rgba(0, 0, 0, 0.2)",
    textAlign: "center",
    width: "100%",
  },
  dotOuter: {
    display: "inline-block",
  },
  dot: {
    width: "15px",
    height: "15px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderRadius: "50%",
    border: "2px solid rgba(255, 255, 255, 0.5)",
    boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.1)",
    margin: "5px"
  },
  filled: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderColor: "rgba(255, 255, 255, 1.0)",
    boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.2)",
  }
});

export const PortfolioNavigation = (props: {
  items: IPortfolioItem[],
  index: number,
  onClicked: (index: number) => void
}) => {
  const styles = useStyle();
  return (
    <div className={styles.nav}>
      {props.items.map((item, index) => {
        return (<div key={index} className={styles.dotOuter} onClick={(e) => {
          props.onClicked(index);
          e.preventDefault();
          e.stopPropagation();
        }}><div key={index} className={styles.dot + (props.index === index ? " " + styles.filled : "")}/></div>);
      })}
    </div>
  );
};
