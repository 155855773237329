import React from "react";

interface INavItemStyles {
  navItem: string;
  navSelected: string;
}

export const NavItem = (props: {
  text: string,
  href: string,
  active?: boolean,
  styles: INavItemStyles,
  onClick: (event: React.MouseEvent) => void
}) => {
  const styles = props.styles;
  return (
    <li className={styles.navItem + " " + (props.active ? styles.navSelected : "")} onClick={props.onClick}>
      <a data-scroll="" href={props.href}>{props.text}</a>
    </li>
  );
};
