import React from "react";
import { throttle } from "./Utils";

export interface IScrollItemOverride {
  id: string;
  fixedPos?: number;
}

function getScrollY(ref: HTMLElement | Window) {
  const el = ref as any;
  const py = el.pageYOffset !== undefined ? el.pageYOffset : el.scrollTop;
  return py;
}

function findChildById(children: HTMLCollection, id: string) {
  const elements = Array.from(children);
  for (const c of elements) {
    if (c.id === id) {
      return (c as any);
    }
  }
}

function findOverride(overrides: IScrollItemOverride[] | undefined, id: string) {
  if (!overrides)
    return undefined;
  return overrides.find((o) => o.id === id);
}

export const ScrollMarker = (props: {
  id: string,
  pos?: number
}) => {
  return (
    <div id={props.id}></div>
  );
};

interface IFoundId {
  id: string;
  posY: number;
}

const handleScrollInner = (scrollY: number, scrollTarget: HTMLElement | null, onScroll?: (scrollY: number, found: IFoundId[]) => void) => {
  // console.log("ScrollY: " + scrollY);
  if (scrollTarget) {
    const elements = Array.from(scrollTarget.children);
    if (onScroll) {
      const found: IFoundId[] = [];
      for (const c of elements) {
        if (c.id) {
          const posY = (c as any).offsetTop - scrollTarget.offsetTop;
          if (scrollY >= posY - scrollTarget.clientHeight * (scrollY / (scrollTarget.scrollHeight - scrollTarget.clientHeight)) /*0.5*/) {
            found.push({ id: c.id, posY });
            // console.log(c.id + " " + posY);
          }
        }
      }
      found.sort((a, b) => a.posY - b.posY);
      onScroll(scrollY, found);
    }
  }
};

const getScrollThrottled = (scrollTarget: HTMLElement | null, onScroll?: (scrollY: number, found: IFoundId[]) => void ) => {
  return throttle((scrollY: number) => handleScrollInner(scrollY, scrollTarget, onScroll), 300);
};

export const useScrollHelper = ( onScroll?: (scrollY: number, found: IFoundId[]) => void, options?: {
  overrides?: IScrollItemOverride[],
}) => {
  const [scrollTarget, setScrollTarget] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    const throttled = getScrollThrottled(scrollTarget, onScroll);
    const handleScroll = (event: Event) => {
      // console.log("Handle Scroll");
      throttled(getScrollY(event.currentTarget as any));
    };

    const st = scrollTarget;
    if (st)
      st.addEventListener("scroll", handleScroll);
    return () => {
      if (st)
        st.removeEventListener("scroll", handleScroll);
    };
  }, [scrollTarget, onScroll]);

  return {
    scrollTo: (id: string) => {
      if (!id.startsWith("#"))
        return;
      id = id.substring(1);

      if (scrollTarget !== null) {
        let posY: number | undefined;
        const override = options && options.overrides && findOverride(options.overrides, id);
        if (override && override.fixedPos !== undefined)
          posY = override.fixedPos;
        else {
          const element = findChildById(scrollTarget.children, id);
          if (element)
            posY = (element as any).offsetTop - scrollTarget.offsetTop;
        }

        if (posY !== undefined) {
          scrollTarget.scrollTo({
            top: posY,
            left: 0,
            behavior: "smooth"
          });
        }
      }
    },
    setRef: (st: HTMLElement | null) => {
      setScrollTarget(st);
    }
  };
};
