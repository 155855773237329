import React from "react";
import { makeStyles } from "@material-ui/core";
import { useWebsiteContext } from "./WebsiteContext";

const useStyle = makeStyles({
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },
  item: {
    flex: 1,
    position: "relative"
  }
});

export const Row = (props: {
  children: JSX.Element[],
  maxPerRow?: number,
}) => {
  const context = useWebsiteContext();
  const styles = useStyle();
  const maxPerRow = props.maxPerRow ? props.maxPerRow : 2;
  const itemsPerRow = context.size === "small" ? Math.floor(maxPerRow / 2) : maxPerRow;
  const rowCount = Math.ceil(props.children.length / itemsPerRow);
  const rowChildren: JSX.Element[][] = [];
  for (let iRow = 0; iRow < rowCount; iRow++) {
    const startIndex = iRow * itemsPerRow;
    rowChildren.push(props.children.slice(startIndex, startIndex + itemsPerRow));
  }
  return (
    <>
      {rowChildren.map((row, rowIndex) => {
        return (
          <div key={rowIndex} className={styles.row}>
            {row.map((r, colIndex) => {
              return <div key={rowIndex + "_" + colIndex} className={styles.item}>{r}</div>;
            })}
          </div>
        );
      })}
    </>
  );
};
