import { constants } from "./Definitions";

export interface ILocalData {
  privacyVersion: number;
  privacyAccepted: number;
}

export let localData: ILocalData = {
  privacyVersion: constants.currentPrivacyVersion,
  privacyAccepted: 0
};

export function tryLoadLocalData() {
  const data = localStorage.getItem("localData");
  if (data) {
    try {
      const parsedValue = JSON.parse(data) as (typeof localData);
      localData = parsedValue;
    } catch (e) {
      console.log("Failed parsing local data!");
    }
  }
}

export function trySaveLocalData() {
  localStorage.setItem("localData", JSON.stringify(localData));
}
