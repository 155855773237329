export const colors = {
  white: "white",
  appBar: "#1d2024",
  navInactive: "#737f8a",
  navActive: "#80b2e1",
  greyFont: "#444",
  darkgreyFont: "#222",
  menuColor: "#292f36"
};

export type Pages = "Main" | "Imprint" | "Privacy";

export const constants = {
  currentPrivacyVersion: 1574338420506
};

export interface IPageItem {
  text: string;
  page: Pages;
}
