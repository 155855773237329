import React from "react";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
import { makeStyles, useMediaQuery, Drawer, Button } from "@material-ui/core";
import { MainPage } from "./MainPage";
import { useScrollHelper } from "./ScrollHelper";
import { WebsiteContext } from "./WebsiteContext";
import { PrivacyPage } from "./PrivacyPage";
import { ImprintPage } from "./ImprintPage";
import { colors, constants, IPageItem } from "./Definitions";
import { localData, trySaveLocalData } from "./LocalData";

const useStyles = makeStyles({
  "@global": {
    "html, body, #root": {
      width: "100%",
      height: "100%",
      "& a:link, & a:hover, & a:visited, & a:active": {
        color: "inherit",
        // textDecoration: "none",
      },
    },
    "html": {
      minWidth: "320px",
      backgroundColor: colors.appBar,
      "@media (max-width: 640px), (max-height: 640px)": {
        fontSize: "85%"
      }
    },
  },
  app: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  content: {
    width: "100%",
    flex: "1 0 0%",
    overflowY: "auto",
    backgroundColor: colors.white,
    display: "flex",
    flexDirection: "column",
  },
  testLayer: {
    width: "100px",
    height: "100px",
    backgroundColor: "red"
  },
  privacy: {
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
});

const navBarItems = [
  {text: "Home", href: "#home"},
  {text: "Portfolio", href: "#portfolio"},
  {text: "Contact", href: "#contacts"}
];

const pageItems: IPageItem[] = [
  {text: "Datenschutz", page: "Privacy"},
  {text: "Impressum", page: "Imprint"},
];

export const App: React.FC = () => {
  // let startPage = "Imprint";
  let startPage = "Main";
  {
    const params = window.location.pathname.split("/").filter((e) => !!e);
    if (params.length > 0) {
      if (params[0] === "pages" && params.length > 1) {
        if (params[1] === "privacy") {
          startPage = "Privacy";
        }
        if (params[1] === "imprint") {
          startPage = "Imprint";
        }
      }
    }
  }

  const styles = useStyles();
  const isSmall = useMediaQuery("(max-width:640px)");
  const [activeRef, setActiveRef] = React.useState("#home");
  const [pageStack, setPageStack] = React.useState([startPage]);
  const [termsAccepted, setTermsAcceptedState] = React.useState<number>(localData.privacyAccepted);
  const scrollHelper = useScrollHelper((scrollY, found) => {
    if (found.length > 0) {
      found = found.reverse();
      for (const f of found) {
        const ref = "#" + f.id;
        if (navBarItems.findIndex((nbi) => nbi.href === ref) !== -1) {
          setActiveRef(ref);
          // console.log("Active ref" + ref);
          return;
        }
      }
    }
  });

  const setTermsAccepted = (date: number) => {
    setTermsAcceptedState(date);
    localData.privacyAccepted = date;
    localData.privacyVersion = constants.currentPrivacyVersion;
    trySaveLocalData();
  };

  const switchToSection = (section: string) => {
    if (pageStack[0] !== "Main") {
      setPageStack(["Main"]);
      setTimeout(() => {
        scrollHelper.scrollTo(section);
      }, 100);
      return true;
    } else {
      scrollHelper.scrollTo(section);
      return true;
    }
  };

  return (
    <WebsiteContext.Provider value={{
      size: isSmall ? "small" : "large",
      termsAccepted,
      switchToSection
    }}>
      <div className={styles.app}>
        <NavBar
          items={navBarItems}
          activeHRef={pageStack[0] === "Main" ? activeRef : ""}
          onClick={(href: string, ev) => {
            if (switchToSection(href))
              ev.preventDefault();
          }}
        />
        <div className={styles.content} ref={(element) => scrollHelper.setRef(element)}>
          {pageStack[0] === "Main" && <MainPage />}
          {pageStack[0] === "Privacy" && <PrivacyPage />}
          {pageStack[0] === "Imprint" && <ImprintPage />}
          <Footer pages={pageItems} selectedPage={pageStack[0]} onPageClicked={(page) => {
            setPageStack([page]);
          }}/>
        </div>
        {<Drawer anchor="bottom" open={termsAccepted < constants.currentPrivacyVersion && pageStack[0] !== "Privacy"}>
          <div className={styles.privacy}>
            <p>Wie speichern lokale Daten um ein optimale Funktionalität der Seite zu garantieren. Nähere Informationen finden Sie in unseren <a href="/pages/privacy">Datenschutzbestimmungen</a>.</p>
            <Button fullWidth onClick={() => setTermsAccepted(Date.now())}>Akzeptieren</Button>
          </div>
        </Drawer>}
      </div>
    </WebsiteContext.Provider >
  );
};
