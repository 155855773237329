import React from "react";
import {makeStyles} from "@material-ui/core";
import Logo from "./assets/FluchtpunktLogo.jpg";
import { colors } from "./Definitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-duotone-svg-icons";
import { useWebsiteContext } from "./WebsiteContext";
import { NavItem } from "./NavItem";

const navBarHeight = 70;

const useStyle = makeStyles({
  navBar: {
    width: "100%",
    backgroundColor: colors.appBar,
    height: navBarHeight + "px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "spaceBetween",
    position: "sticky",
    top: "0px",
    zIndex: 1500
  },
  link: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "spaceBetween",
    "& img": {
      height: "60px",
      margin: "auto 20px"
    }
  },
  navContainer: {
    display: "flex",
    flexDirection: "row",
    paddingRight: "20px",
    alignItems: "center"
  },
  icon: {
    color: colors.navActive,
    padding: "10px"
  },
  menuContainer: {
    zIndex: 1499,
    position: "absolute",
    width: "100%",
    top: "-200px",
    opacity: 0,
    transition: "opacity 0.3s, top 0.3s"
  },
  menuContainerOpen: {
    top: "70px",
    opacity: 1
  },
  clickDiv: {
    zIndex: 1498,
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    // backgroundColor: "rgba(0, 255, 0, 0.5)"
  }
});

const useNavItemTextStyles = makeStyles({
  navItem: {
    padding: "10px",
    fontSize: "100%",
    listStyle: "none",
    color: colors.navInactive,
    "& a:link, & a:hover, & a:visited, & a:active": {
      color: "inherit",
      textDecoration: "none",
    },
  },
  navSelected: {
    color: colors.navActive
  },
  outer: {
    display: "flex"
  }
});

const useNavItemMenuStyles = makeStyles({
  navItem: {
    padding: "10px 15px",
    fontSize: "150%",
    listStyle: "none",
    color: colors.navInactive,
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    margin: "5px",
    "& a:link, & a:hover, & a:visited, & a:active": {
      color: "inherit",
      textDecoration: "none",
    },
  },
  navSelected: {
    color: colors.navActive
  },
  outer: {
    backgroundColor: colors.menuColor,
    textAlign: "center",
    padding: "5px 0px",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginInlineStart: 0,
    marginInlineEnd: 0,
    paddingInlineStart: 0
  }
});

export const NavBar = (props: {
  items: Array<{ text: string, href: string}>,
  activeHRef: string,
  onClick?: (id: string, ev: React.MouseEvent) => void
}) => {
  const context = useWebsiteContext();
  const isHamburger = context.size === "small";
  const styles = useStyle();
  const menuStyles = useNavItemMenuStyles();
  const textStyles = useNavItemTextStyles();
  const navItemStyles = isHamburger ? menuStyles : textStyles;
  const [menuOpen, setMenuOpen] = React.useState(false);

  const toggleMenuOpen = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const renderNavItems = () => {
    return (
      <ul className={navItemStyles.outer}>
        {props.items.map((item) => {
          return <NavItem text={item.text} href={item.href} key={item.href} active={props.activeHRef === item.href} styles={navItemStyles} onClick={(ev) => {
            if (props.onClick) {
              props.onClick(item.href, ev);
              setMenuOpen(false);
            }
          }} />;
        })}
      </ul>
    );
  };

  return (
    <>
    <nav className={styles.navBar}>
      <a className={styles.link} href="/">
        <img src={Logo} alt="Logo"/>
      </a>
      <div className={styles.navContainer}>
        {isHamburger && <div onClick={toggleMenuOpen}><FontAwesomeIcon className={styles.icon} icon={faBars} size="2x"/></div>}
        {!isHamburger && renderNavItems() }
      </div>
    </nav>
    {isHamburger && menuOpen && <div className={styles.clickDiv} onClick={() => setMenuOpen(false)}/>}
    {isHamburger &&
      <div
        className={styles.menuContainer + " " + (menuOpen ? styles.menuContainerOpen : "")}
      >
        {renderNavItems()}
      </div>}
    </>
  );
};
